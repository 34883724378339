import { useState } from "react";
import Contact from "../HomePage/Contact";
import "./ContactPage.css";
import emailjs from "emailjs-com";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_88gvenr",
        "template_pzvtyg3",
        e.target,
        "udoUfSSRfzAZBebC_"
      )
      .then(
        (result) => {
          //window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div>
        <div className="hero-section contact-hero-section">
          <div className="hero-title">
            <h2 className="hero-text">Contact Us</h2>
            <p
              className="hero-text"
              style={{
                fontSize: "19px",
                lineHeight: "1.3",
                fontWeight: "300",
                fontFamily: "system-ui",
              }}
            >
              At Ebaad Digital, we specialize in delivering exceptional digital
              experiences. From web development to design and maintenance, our
              experts are here to help. Contact us today to learn more about how
              we can achieve your software development goals.
            </p>
          </div>
          <div>
            <form id="contact-form" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="company">Company Name</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>

      <div className="contact-mobile-view">
        <Contact />
      </div>
    </div>
  );
};

export default ContactPage;
